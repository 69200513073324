import { Button, notification, Tabs } from "antd";
import { useState, useEffect } from "react";
import REQUESTS from "../../../../api/requests";
import TranslationInput from "./components/TranslationInput";
import Language from "./Language";

import classNames from "./web-translation.module.scss";
import TextEditor from "./TextEditor";

export default function WebTranslation() {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [translations, setTranslations] = useState({});
  const [webSettings, setWebSettings] = useState(null);
  const [activeTab, setActiveTab] = useState("general");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onSave = () => {
    let allTranslations = {};

    Object.keys(translations).map((item) => {
      allTranslations[item] = {
        general: {},
        pages: {},
      };

      translations[item].general.map((i) => {
        allTranslations[item].general[i.key] = i.value;
      });

      Object.keys(translations[item]).map((key) => {
        if (key !== "general") {
          allTranslations[item].pages[key] = {};

          translations[item][key].map((i) => {
            allTranslations[item].pages[key][i.key] = i.value;
          });
        }
      });
    });

    let body = {
      ...webSettings,
      translations: allTranslations,
    };

    REQUESTS.TRANSLATIONS.EDIT(body, (data) => {
      notification.success({
        message: "Success",
        description: "Translation saved successfully",
      });
    });
  };

  const onTranslate = (value, key, type) => {
    let newTranslations = { ...translations };

    console.log(value, key, type);

    for (let i = 0; i < newTranslations[selectedLanguage][type].length; i++) {
      if (newTranslations[selectedLanguage][type][i].key === key) {
        newTranslations[selectedLanguage][type][i].value = value;
      }
    }

    setTranslations(newTranslations);
  };

  const returnLanguageTranslation = (data, en) => {
    const result = Object.keys(data).map((item) => ({
      key: item,
      label: en[item],
      value: data[item],
    }));

    return result;
  };

  const getTranslation = () => {
    REQUESTS.TRANSLATIONS.GET({}, (data) => {
      if (data && data.json_configs) {
        let responseData = JSON.parse(data.json_configs);

        setWebSettings(responseData);

        if (responseData) {
          responseData = responseData.translations;
        }

        const myObj = {};

        Object.keys(responseData).map((item) => {
          myObj[item] = {};
          myObj[item]["general"] = returnLanguageTranslation(
            responseData[item].general,
            responseData?.en?.general
          );

          Object.keys(responseData[item].pages).map((key) => {
            myObj[item][key] = returnLanguageTranslation(
              responseData[item].pages[key],
              responseData?.en?.pages[key]
            );
          });
        });

        setTranslations(myObj);
      }
    });
  };

  useEffect(() => {
    getTranslation();

    const changeWindowWidth = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", changeWindowWidth);

    return () => window.removeEventListener("resize", changeWindowWidth);
  }, []);

  useEffect(() => {
    const newTranslations = { ...translations };
    if (!newTranslations[selectedLanguage]) {
      if (translations.en) {
        newTranslations[selectedLanguage] = JSON.parse(
          JSON.stringify(translations.en)
        );
      }
    }

    setTranslations(newTranslations);
  }, [selectedLanguage]);

  console.log(translations?.[selectedLanguage])
  return (
    <div>
      <div className={classNames["translations-header"]}>
        Web Translation
        <Language onChange={setSelectedLanguage} />
        <Button onClick={onSave} type="primary" style={{ width: 120 }}>
          Save
        </Button>
      </div>

      <Tabs
        tabPosition={windowWidth > 790 ? "left" : "top"}
        defaultActiveKey={activeTab}
        onChange={setActiveTab}
        className="web-translation-tabs-component"
      >
        {translations &&
          translations.en &&
          Object.keys(translations.en).map((item) => {
            if (
              item === "privacy" ||
              item === "terms_of_online_sale" ||
              item === "terms_of_use"
            ) {
              return (
                <Tabs.TabPane
                  tab={
                    item === "general"
                      ? "General"
                      : translations.en[item][0].label
                  }
                  key={item}
                >
                  <>

                    <TranslationInput
                      name={translations?.[selectedLanguage]?.[item][1]['key']}
                      label={<div dangerouslySetInnerHTML={{ __html: translations?.[selectedLanguage]?.[item][1]['label'] }} />}
                      value={translations?.[selectedLanguage]?.[item][1]['value'].replace(/<\/?[^>]+(>|$)/g, "")}
                      onChange={(e) =>
                        onTranslate(e.target.value, e.target.name, item)
                      }
                    />
                    <TextEditor
                      item={translations?.[selectedLanguage]?.[item][2]}
                      onTranslate={(value) => onTranslate(value, translations?.[selectedLanguage]?.[item][2]["key"], item)}
                    />
                  </>
                </Tabs.TabPane>
              );
            } else {
              return (
                <Tabs.TabPane
                  tab={
                    item === "general"
                      ? "General"
                      : translations.en[item][0].label
                  }
                  key={item}
                >
                  <div>
                    {translations?.[selectedLanguage]?.[item].map(
                      (index, i) => {
                        return (index.key == "section_one_description" ||
                          index.key == "section_two_description" ||
                          index.key == "section_three_description" ?
                          <TextEditor
                            item={index}
                            onTranslate={(value) => onTranslate(value, index.key, item)} />
                          :
                          <TranslationInput
                            name={index.key}
                            label={
                              item !== "general" && i === 0
                                ? "Page name"
                                : index.label
                            }
                            value={index.value}
                            onChange={(e) =>
                              onTranslate(e.target.value, e.target.name, item)
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </Tabs.TabPane>
              );
            }
          })}
      </Tabs>
    </div >
  );
}
